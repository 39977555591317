import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import css from './ListingCard.module.css';
const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, rating, numReviews, addOnMax, addOnMin, cameraListings, listing } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  let formattedPriceMin = "";
  let formattedPriceMax = "";

  if(addOnMax) {
    const { formattedPrice, priceTitle  } = priceData(addOnMax, config.currency, intl);

    formattedPriceMax = formattedPrice
  }
  if(addOnMin) {
    const { formattedPrice, priceTitle } = priceData(addOnMin, config.currency, intl);
    formattedPriceMin = formattedPrice
  }

  const formatedPriceFinal = addOnMax && addOnMin && (addOnMax.amount !== addOnMin.amount) ? `${formattedPriceMin} - ${formattedPriceMax}` : formattedPrice;


  return (
    <div className={css.priceAndRating}>
      <div className={css.price}>
        <div className={css.priceValue} title={addOnMax && addOnMin ? formatedPriceFinal : priceTitle}>
          {formatedPriceFinal}
        </div>
        {isBookable ? (
          <div className={css.perUnit}>
            <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
          </div>
        ) : null}
      </div>
      <div>
        {rating && numReviews > 0 && (
          <div className={css.rating}>
            ★ {rating} ({numReviews})
          </div>
        )}
        {cameraListings.includes(listing.id.uuid) && 
          <img src={'https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296139/camera_atsbvs.png'} alt="Featured" className={css.cameraImage} />
        }
      </div>
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;

  const cameraListings = ['660dcc2f-7f38-4142-a8fd-15dd013886f8', '6611ba73-9f75-445d-a022-a41d63f3d4a3', '671893a2-55cb-4c0c-8e52-4b2e209e7977', '66da3996-20ce-4de3-9ded-e845c589098f', '667ccf29-fdd3-4871-9c68-9b5a95947d09', '67a13ad9-f917-4e31-8dbd-ef52b55e29a5', '677dbbc0-199e-462b-aacb-4a526ed8e47b', '677a5257-fb83-43d1-930a-334ea30388e2']
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata} = currentListing.attributes;
  const addOns = publicData.addOns ? JSON.parse(publicData.addOns): [];
  const addOnsPrices = addOns.map(addOn => addOn.details)
  const minPriceObject = addOnsPrices.reduce((min, current) => current.amount < min.amount ? current : min, addOnsPrices[0]);
  const maxPriceObject = addOnsPrices.reduce((max, current) => current.amount > max.amount ? current : max, addOnsPrices[0]);

  const minMoney = minPriceObject ? new Money(minPriceObject.amount, minPriceObject.currency) : null;
  const maxMoney = maxPriceObject ? new Money(maxPriceObject.amount, maxPriceObject.currency) : null

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const rating = metadata?.reviewsAverage ? (metadata.reviewsAverage / 100).toFixed(1) : null;
  const numReviews = metadata?.numReviews || 0;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe 
          price={price} 
          publicData={publicData} 
          config={config} 
          intl={intl} 
          numReviews={numReviews} 
          rating={rating} 
          listing={listing}
          cameraListings={cameraListings}
          addOnMax={addOns.length > 1 ? maxMoney : null} 
          addOnMin={addOns.length > 1 ? minMoney : null}/>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);